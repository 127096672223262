<template>
  <div id="nav">
    <header id="header" class="fixed-top ">
      <div class="container d-flex align-items-center"><a href="" class="logo"><img src="@/assets/img/logo-ykep.png" alt="" class="img-fluid"></a><div class="mr-auto text-head-yayasan">YAYASAN KARTIKA EKA PAKSI</div>
      <nav class="main-nav d-none d-lg-block">
        <ul>

          <li v-on:click="go('/');" :class="{ active: $route.path == '/' }" ><a href="#">Beranda</a></li>
          <li class="drop-down" :class="{active: $route.path == '/tentangkami/pengurus' || $route.path == '/tentangkami/struktur' || $route.path == '/tentangkami/sejarah'}"><a href="">Tentang Kami</a>
            <ul>
              <li v-on:click="go('/tentangkami/pengurus');" :class="{ active: $route.path == '/tentangkami/pengurus' }"><a href="#">Pengurus</a></li>
              <li v-on:click="go('/tentangkami/struktur');" :class="{ active: $route.path == '/tentangkami/struktur' }"><a href="#">Struktur</a></li>
              <li v-on:click="go('/tentangkami/sejarah');" :class="{ active: $route.path == '/tentangkami/sejarah' }"><a href="#">Sejarah</a></li>
            </ul>
          </li>
          <li v-on:click="go('/visimisi');" :class="{ active: $route.path == '/visimisi' }"><a href="#">Visi Misi</a></li>
          <li v-on:click="go('/program');" :class="{ active: $route.path == '/program' }"><a href="#">Program</a></li>
          <li v-on:click="go('/berita');" :class="{ active: $route.path == '/berita' }"><a href="#">Berita</a></li>
          <li v-on:click="go('/galery');" :class="{ active: $route.path == '/galery' }"><a href="#">Galery</a></li>
          <li v-on:click="go('/kontak');" :class="{ active: $route.path == '/kontak' }"><a href="#">Kontak</a></li>
        </ul>
      </nav>
    </div>
  </header>
</div>

<router-view/>

<footer id="footer" class="section-bg bg-3">
  <div class="footer-top bg-2">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-3">
          <div class="footer-info text-center">
            <img class="img-footer" src="@/assets/img/logo-ykep.png" >
            <br>
            <br>
            <p>Yayasan Kartika Eka Paksi</p>
          </div>
        </div>
        <div class="col-md-12 col-lg-3 pl-5">
          <div class="footer-links">
            <h4 class="text-left">Peta Situs</h4>
            <ul class=" text-left">
              <div class="row">
                <div class="col"> 
                 <li><i class="fa fa-chevron-right "></i>  <router-link class="a" to="/">Beranda</router-link></li>
                 <li><i class="fa fa-chevron-right "></i>  <router-link class="a" to="/visimisi">Visi Misi</router-link></li>
                 <li><i class="fa fa-chevron-right "></i>  <router-link class="a" to="/tentangkami/pengurus">Pengurus</router-link></li>
                 <li><i class="fa fa-chevron-right "></i>  <router-link class="a" to="/tentangkami/struktur">Struktur</router-link></li>
                 <li><i class="fa fa-chevron-right "></i>  <router-link class="a" to="/tentangkami/sejarah">Sejarah</router-link></li>
                </div>
                <div class="col"> 
                  <li><i class="fa fa-chevron-right "></i>  <router-link class="a" to="/berita">Berita</router-link></li>
                  <li><i class="fa fa-chevron-right "></i>  <router-link class="a" to="/program">Program</router-link></li>
                  <li><i class="fa fa-chevron-right "></i>  <router-link class="a" to="/galery">Galery</router-link></li>
                  <li><i class="fa fa-chevron-right "></i>  <router-link class="a" to="/kontak">Kontak</router-link></li>
                </div> 
              </div>
            </ul>
          </div>
        </div>
        <div class="col-md-12 col-lg-3 pl-5">
          <div class="footer-links">
            <h4 class="text-left">Tautan Terkait</h4>
            <ul class="text-left">
              <li><i class="fa fa-chevron-right "></i>  <a href="https://www.unjani.ac.id" target="_blank">Unjani</a></li>
              <li><i class="fa fa-chevron-right "></i>  <a href="https://unjaya.ac.id" target="_blank">Unjaya</a></li>
              <li><i class="fa fa-chevron-right "></i>  <a href="https://www.astonhotelsinternational.com/id/hotel/view/59/aston-kartika-grogol-hotel---conference-center" target="_blank">Aston</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 col-lg-3 pl-5">
          <div class="footer-links">
            <h4 class="text-left">Kontak Kami</h4>
            <div class="row">
              <div class="col-2 text-left pr-0">
                <i class="fa fa-home text-white" style="font-size: 24px;" aria-hidden="true"></i>
              </div>
              <div class="col-10">
                <p class="text-left mb-3">
                  Jalan Medan Merdeka Tim No. 7,
              RT.2/RW.1, Gambir, Kota Jakarta Pusat,
              Daerah Khusus Ibu Kota 10110
                </p>  
              </div>
            </div>
             <div class="row">
              <div class="col-2 text-left pr-0">
                <i class="fa fa-phone text-white" style="font-size: 24px;" aria-hidden="true"></i>
              </div>
              <div class="col-10">
                <p class="text-left mb-3">
                  (021) 3840424
                </p>  
              </div>
            </div>
             <div class="row">
              <div class="col-2 text-left pr-0">
                <i class="fa fa-envelope text-white" style="font-size: 24px;" aria-hidden="true"></i>
              </div>
              <div class="col-10">
                <p class="text-left m-0">
                  yayasankartikaekapaksi@gmail.com
                </p>  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="copyright">
      <strong>Copyright &copy; 2021 Sistem Informasi YKEP</strong>, All Rights Reserved.
    </div>
  </div>
</footer>
</template>

<style>
  .text-head-yayasan{
    padding-left:10px;
    font-weight: 600;
  }
  .main-nav .active > a, .main-nav .active > a:hover{
    color: #fff !important;
    background: #425e4f;
    padding: 10px;
  }
  .main-nav a:hover, .main-nav li:hover > a{
    color: #009f65 !important;
  }
  .main-nav a {
      color: #425e4f !important;
  }
  #why-us {
    padding: 60px 125px;
  }

  @media screen and (max-width: 600px) {
    #why-us {
     padding: 34px 30px !important;
   }
 }

 #footer .copyright {
    font-size: 12px !important;
  }
  #footer .footer-top {
    padding: 35px 0 0px 0 !important;
  }
</style>

<script>
  export default {
    data() {
      return { activeItem: 'beranda' }
    },
    mounted(){
     /* if ($('.main-nav').length) {
        var $mobile_nav = $('.main-nav').clone().prop({
          class: 'mobile-nav d-lg-none'
        });
        $('body').append($mobile_nav);
        $('body').prepend('<button type="button" class="mobile-nav-toggle d-lg-none"><i class="fa fa-bars"></i></button>');
        $('body').append('<div class="mobile-nav-overly"></div>');

        $(document).on('click', '.mobile-nav-toggle', function(e) {
          $('body').toggleClass('mobile-nav-active');
          $('.mobile-nav-toggle i').toggleClass('fa-times fa-bars');
          $('.mobile-nav-overly').toggle();
        });

        $(document).on('click', '.mobile-nav .drop-down > a', function(e) {
          e.preventDefault();
          $(this).next().slideToggle(300);
          $(this).parent().toggleClass('active');
        });

        $(document).click(function(e) {
          var container = $(".mobile-nav, .mobile-nav-toggle");
          if (!container.is(e.target) && container.has(e.target).length === 0) {
            if ($('body').hasClass('mobile-nav-active')) {
              $('body').removeClass('mobile-nav-active');
              $('.mobile-nav-toggle i').toggleClass('fa-times fa-bars');
              $('.mobile-nav-overly').fadeOut();
            }
          }
        });
      } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
        $(".mobile-nav, .mobile-nav-toggle").hide();
      }*/
    },
    methods: {
      go(link){
        this.$router.push({ path: link })
      }
    }
  };
</script>
