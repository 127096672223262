<template>
	<div>
		<div class="container-fluid pl-0 pr-0 header-banner">
			<img src="@/assets/img/baner-galeri.jpg" class="w-100" alt="">
		</div>
		<div class="container mt-5" v-if="c_foto">
			<header class="section-header text-center">
				<h3>Foto</h3>
				<div class="divider-custom-3">
					<div class="divider-custom-line-3"></div>
				</div>
			</header>
			<div class="row">
				<div class="col-md-12 col-lg-3 mb-4" v-for="item in data_foto" :key="item.id">	
					<div class="card" style="border-radius: 10px;border: none;box-shadow: 0 0 5px rgb(0 0 0 / 20%);">
						<div class="img_wrap-foto-galery text-center" v-on:click="zoomImg(item.picture,item.description)">
							<img class="card-img-top w-100 img_foto" :src="item.thumbnail" alt="Card image cap">
							<p class="img_desc" style="padding-top: 1.3em;font-size: 3em;"><i class="fa fa-search-plus" aria-hidden="true"></i></p>
						</div>
						<div class="card-body">
							<h5 class="card-title" style="font-weight: 400;font-size: 14px;" v-html="item.description"></h5>
						</div>
					</div>
				</div>
				<div class="col-md-12 col-lg-3 mb-4 my-auto" v-if="nextfoto">
					<div class="card" style="box-shadow: none;width: fit-content;">
						<button class="btn btn-berita w-100" v-on:click="loadFoto(this.counter_foto)">Muat Lebih Banyak ...</button>
					</div>
				</div>
			</div>
		</div>

		<div class="container mt-5 mb-5 container-video" v-if="c_video">
			<header class="section-header text-center">
				<h3>Video</h3>
				<div class="divider-custom-3">
					<div class="divider-custom-line-3"></div>
				</div>
			</header>
			<div class="row">
				<div class="col-md-12 col-lg-4 mb-4" v-for="item in data_video" :key="item.id">
					<div class="card">
						<div class="card-img-top" v-on:click="playVideo(item.youtube_id)">
							<span class="play_btn"><i class="fa fa-play-circle-o" aria-hidden="true"></i></span>
							<img :src="item.cover" class="w-100 img-buat-video">
						</div>
						<div class="card-body">
							<p class="card-text" v-html="item.title"></p>
						</div>
					</div>
				</div>
				<div class="col-md-12 col-lg-4 mb-4" style="padding: 125px;">
					<div class="card" style="box-shadow: none;width: fit-content;">
						<button class="btn btn-berita w-100" v-if="nextvideo" :disabled="nextvideo == 'Akhir dari video!'" v-on:click="loadVideo(this.counter_video)">{{ nextvideo }}</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal"  id="imgModal" tabindex="-1" role="dialog">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title"></h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<img :src="imageInZoom" class="w-100">
					</div>
					<div class="modal-body">
						<p class="text-justify mb-3" style="font-size: 16px;font-weight: 500;">{{ descInZoom }}</p>
					</div>
				</div>
			</div>
		</div>

		<div class="modal"  id="videoModal" tabindex="-1" role="dialog">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title"></h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<iframe width="100%" height="360px" 
							:src="videoInPlay" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
						</iframe>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<style>
	@media screen and (max-width: 600px){
		#imgModal .modal-dialog {
			top: 10% !important;
			max-width: 100% !important;
		}
		#videoModal .modal-dialog {
			top: 10% !important;
			max-width: 100% !important;
		}
	}
	#imgModal .modal-dialog {
		max-width: 40%;
		max-height: 40%;
	}
	#imgModal .modal-dialog .modal-content{
		border-radius: 15px;
	}
	#imgModal .modal-dialog .modal-content .modal-body .card{
		border-radius: 15px;
		border: none;
		box-shadow: 0 0 5px rgb(0 0 0 / 20%);
	}
	#imgModal .modal-dialog .modal-content .modal-header{
		border-bottom: none;
	}
	#videoModal .modal-dialog {
		max-width: 60%;
		max-height: 60%;
	}
	#videoModal .modal-dialog .modal-content{
		border-radius: 15px;
	}
	#videoModal .modal-dialog .modal-content .modal-body .card{
		border-radius: 15px;
		border: none;
		box-shadow: 0 0 5px rgb(0 0 0 / 20%);
	}
	#videoModal .modal-dialog .modal-content .modal-header{
		border-bottom: none;
	}
	.img_wrap-foto-galery {
		position: relative;
		height: 200px;
		width: 257px;
	}

	.img_desc {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin:auto;
		background: rgb(7 157 132 / 79%);
		color: #fff;
		visibility: hidden;
		opacity: 0;
		transition: opacity .2s, visibility .2s;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}

	.img_wrap-foto-galery:hover .img_desc {
		visibility: visible;
		opacity: 1;
	}

	.img_foto{
		object-fit: cover;
		height: 100%;
		border-top-left-radius: 10px !important;
		border-top-right-radius: 10px !important;
	}

	.img-buat-video{
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
		height: 217px;
		object-fit: cover;
	}
	.frame-video-galery{
		position: relative;
		overflow: hidden;
		height: 240px;
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
	}
	.container-video .card-body{
		padding : 20px 15px;
	}
	.container-video .card-body p{
		font-weight: 400;
		font-size: 14px;
	}
	.container-video .card{
		border-radius: 15px !important;
		border: none;
		box-shadow: 0 0 5px rgb(0 0 0 / 20%);
	}
	.play_btn{
		position: absolute;
		top: 25%;
		bottom: auto;
		left: 0;
		right: 0;
		text-align: center;
	}
	.play_btn i{
		font-size: 50px;
		background-color: #38c0a699;
		padding: 11px;
		border-radius: 10px;
		color: #fff;
	}
	.play_btn:hover{
		transform: scale(1.1);
		cursor: pointer;
	}
</style>

<script>
const axios = require('axios').default;
import $ from "jquery";
export default{
	data(){
		return{
			data_foto: [],
			data_video: [],
			imageInZoom: '',
			descInZoom: '',
			videoInPlay: '',
			c_foto:false,
			c_video:false,
			nextvideo:'',
			nextfoto:null,
			counter_video:0,
			counter_foto:0
		}
	},
	mounted(){
		this.loadFoto(1)
		this.loadVideo(1)
	},
	methods:{
		zoomImg(img,desc){
			$('#imgModal').modal('show')
			this.imageInZoom = img
			this.descInZoom = desc
		},
		playVideo(url){
			$('#videoModal').modal('show')
			this.videoInPlay = 'https://www.youtube.com/embed/'+url
		},
		loadFoto(page){
			this.counter_foto = page+1
			axios
			.get('https://admin.ykep.org/entry/services/index/photo?pagenum='+page)
			.then(response => {
				if(response.data.status == 'success'){
					this.c_foto = true
					for (var i = (response.data.results).length -1; i >= 0; i--) {
						this.data_foto.push({
							id: response.data.results[i].id,
							title: response.data.results[i].title,
							description: response.data.results[i].description,
							thumbnail: response.data.results[i].thumbnail,
							picture: response.data.results[i].picture,
							created_at: response.data.results[i].created_at
						})
					}
					if(response.data.status == 'success'){
						this.nextfoto = response.data.pagination
					}else{
						this.nextfoto = null
					}
				}else if(this.data_foto.length == 0){
					this.c_foto = false
				}else if(response.data.status == 'failed'){
					this.nextfoto = null
				}
			})
			.catch(function(){
				console.log('FAILURE!!');
			}
			);
		},
		loadVideo(page){
			this.counter_video = page+1
			axios
			.get('https://admin.ykep.org/entry/services/index/video?pagenum='+page)
			.then(response => {
				if(response.data.status == 'success'){
					this.c_video = true
					for (var i = (response.data.results).length -1; i >= 0; i--) {
						this.data_video.push({
							id: response.data.results[i].id,
							title: response.data.results[i].title,
							description: response.data.results[i].description,
							cover: response.data.results[i].cover,
							youtube_id: response.data.results[i].youtube_id,
							created_at: response.data.results[i].created_at
						})
					}
					if(response.data.pagination){
						this.nextvideo = 'Muat lebih banyak ...'
					}else{
						this.nextvideo = ''
					}
				}else if(this.data_video.length == 0 && response.data.status == 'failed'){
					this.c_video = false
				}

				if(response.data.status == 'failed' && this.data_video.length > 0){
					this.nextvideo = 'Akhir dari video!'
				}
			})
			.catch(function(){
				console.log('FAILURE!!');
			}
			);
		}
	}
};
</script>