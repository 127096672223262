<template>
	<div>
		<div class="container-fluid pl-0 pr-0 header-banner">
			<img src="@/assets/img/baner-program.jpg" class="w-100" alt="">
		</div>
		<div class="wrap">
			<div class="container">
				<div class="row mb-3" v-for="dataProgram of dataPrograms" :key="dataProgram.id">
					<div class="col text-center align-self-center">
						<h3 class="title-program">{{ dataProgram.nama }}</h3>
						<button class="btn btn-program btn-sm " v-on:click="openmodal(dataProgram)">BACA SELENGKAPNYA <i class="fa fa-chevron-circle-right"></i></button>
					</div>
					<div class="col align-self-center">
						<img class="img-program" :src="dataProgram.img" >
					</div>
				</div>
			</div>
		</div>
		<a href="#" class="back-to-top"><i class="fa fa-chevron-up"></i></a>
		<div class="modal"  id="programModal" tabindex="-1" role="dialog">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-12 text-center">
								<img :src="modalimg" class="w-100">
							</div>
						</div>
						<div class="row">
							<div class="col-12 text-center mt-3">
								<h2>{{ modalNamaProgram }}</h2>
								<div class="divider-custom-3">
									<div class="divider-custom-line-3"></div>
								</div>
							</div>
							<div class="col-12">
								<p class="text-justify">
									{{ modalnarasi }}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style>
	@media screen and (max-width: 600px) {
		#programModal .modal-dialog {
			max-width: 100% !important;
			top: 10%;
		}
		.title-program{
			font-size: 19px;
		}
		.btn-program{
			font-size: 11px !important;
		}
	}
	#programModal .modal-dialog {
		max-width: 50%;
	}
	#programModal .modal-dialog .modal-content{
		border-radius: 15px;
	}
	#programModal .modal-dialog .modal-content .modal-header{
		border-bottom: none;
		padding-left: 3em;
	}
	.btn-program{
		background-color: #68afab;
		color: #e6eeee;
		font-size: 13px;
		border-radius: 8px;
	}
	.title-program{
		font-weight: 1000
	}
	.p-10{
		padding:0em 10em !important;
	}
	.icon-misi{	
		text-align: center;
		font-size: 4em;
	}
	.logo-text{
		color: #ffffff !important;
	}
	.img-program{
		width: 100%;
	}
</style>

<script>
	import $ from "jquery";
	export default{
		data(){
			return{
				modalNamaProgram: '',
				modalimg: '',
				modalnarasi: '',
				dataPrograms:[
				{
					id: 1,
					nama: 'New Unjani',
					img: require('@/assets/img/new-unjani.png'),
					narasi:'',
				},
				{
					id: 2,
					nama: 'The Riverpark Residence',
					img: require('@/assets/img/the-river-park.png'),
					narasi:'',
				}]
			}
		},
		methods:{
			openmodal(item){
				this.modalNamaProgram = item.nama
				this.modalimg = item.img
				this.modalnarasi = item	.narasi
				$('#programModal').modal('show')
			}
		}
	};
</script>