<template>
	<div class="struktur">
		<div class="container-fluid pl-0 pr-0 header-banner">
			<img src="@/assets/img/baner-struktur.jpg" class="w-100" alt="">
		</div>
		<div class="wrap bg-1">
			<div class="container">
				<div class="row">
					<div class="col-md-12 col-lg-4 align-self-center">
						<div class="text-center judul-struktur mt-2">
							<h5>STRUKTUR ORGANISASI <br>YAYASAN</h5>
							<div class="divider-custom-2">
								<div class="divider-custom-line-2"></div>
							</div>
						</div>
					</div>
					<div class="col-md-12 col-lg-8 align-self-center text-center">
						<div>
							<img src="@/assets/img/struktur-org-ykep.webp" class="w-50">
						</div>
					</div>
				</div>
				<div class="row mt-5" data-aos="fade-up">
					<div class="col-md-12 col-lg-4 align-self-center">
						<div class="text-center judul-struktur mt-2">
							<h5>STRUKTUR ORGANISASI <br>PEMBINA</h5>
							<div class="divider-custom-2">
								<div class="divider-custom-line-2"></div>
							</div>
						</div>
					</div>
					<div class="col-md-12 col-lg-8 align-self-center text-center">
						<div>
							<img src="@/assets/img/struktur-org-pembina.webp" class="w-50">
						</div>
					</div>
				</div>
				<div class="row mt-5" data-aos="fade-up">
					<div class="col-md-12 col-lg-4 align-self-center">
						<div class="text-center judul-struktur mt-2">
							<h5>STRUKTUR ORGANISASI <br>PENGAWAS</h5>
							<div class="divider-custom-2">
								<div class="divider-custom-line-2"></div>
							</div>
						</div>
					</div>
					<div class="col-md-12 col-lg-8 align-self-center text-center">
						<div>
							<img src="@/assets/img/struktur-org-pengawas.webp" class="w-50">
						</div>
					</div>
				</div>
				<div class="row mt-5" data-aos="fade-up">
					<div class="col-12">
						<div class="text-center judul-struktur mt-2">
							<h5>STRUKTUR ORGANISASI KEPENGURUSAN YAYASAN</h5>
							<div class="divider-custom-2">
								<div class="divider-custom-line-2"></div>
							</div>
						</div>
					</div>
					<div class="col-12 align-self-center text-center">
						<div>
							<img src="@/assets/img/struktur-kepengurusan-YAKEP.jpg" class="w-75">
						</div>
					</div>
				</div>
			</div>
		</div>
		<a href="#" class="back-to-top"><i class="fa fa-chevron-up"></i></a>
	</div>
</template>

<style>
	.w-30{
		width: 30%
	}
	.w-46{
		width: 46%
	}
	.judul-struktur{
		color: #ffffff;
}
</style>