<template>
  <div class="tentang">
    <router-view></router-view>
  </div>
</template>

<script>
	export default {
		name: 'VTentangKami'
  }
</script>