import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"

import "@/assets/css/font-awesome/css/font-awesome.min.css";
import "@/assets/css/style.css";

createApp(App).use(router).mount('#app')