import { createRouter, createWebHistory } from 'vue-router'
import Beranda from '../components/VBeranda.vue'

import TentangKami from '../components/VTentangKami.vue'
import TentangPengurus from '../components/VPengurus.vue'
import TentangStruktur from '../components/VStruktur.vue'
import TentangSejarah from '../components/VSejarah.vue'
import Visimisi from '../components/VVisiMisi.vue'
import Program from '../components/VProgram.vue'
import Berita from '../components/VBerita.vue'
import Kontak from '../components/VKontak.vue'
import ProfilPT from '../components/VProfilPT.vue'
import ProfilPendidikan from '../components/VProfilPendidikan.vue'
import BacaBerita from '../components/VBacaBerita.vue'
import Galery from '../components/VGalery.vue'

const routes = [
  {
    path: '/',
    name: 'beranda',
    component: Beranda
  },
  {
    path: '/tentangkami',
    name: 'tentangkami',
    component: TentangKami,
    children: [
      {
        path: 'pengurus',
        name: 'pengurus',
        component: TentangPengurus,         
      },
      {
        path: 'struktur',
        name: 'struktur',
        component: TentangStruktur,         
      },
      {
        path: 'sejarah',
        name: 'sejarah',
        component: TentangSejarah,         
      }        
    ],
    props: true
  },
  {
    path: '/profil-pt',
    name: 'profil-pt',
    component: ProfilPT
  },
  {
    path: '/profil-pendidikan',
    name: 'profil-pendidikan',
    component: ProfilPendidikan 
  },
  {
    path: '/baca-berita',
    name: 'bacaberita',
    component: BacaBerita
  },
  {
    path: '/visimisi',
    name: 'visimisi',
    component: Visimisi
  },
  {
    path: '/program',
    name: 'program',
    component: Program
  },
  {
    path: '/berita',
    name: 'berita',
    component: Berita
  },
  {
    path: '/galery',
    name: 'galery',
    component: Galery
  },
  {
    path: '/kontak',
    name: 'kontak',
    component: Kontak
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router