<template>
	<div class="berita">
		<div class="container-fluid pl-0 pr-0 header-banner">
			<img src="@/assets/img/baner-berita.jpg" class="w-100" alt="">
		</div>
		<div class="wrap">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<div class="text-center">
							<div class="d-inline-block">
								<div class="btn-toolbar mx-auto" role="toolbar" aria-label="Toolbar with button groups">
									<div class="btn-group mr-4 mb-4" role="group" aria-label="Third group">
										<button type="button" class="btn btn-berita" :class="{ active: isActive('all') }" v-on:click="get_berita()">Semua Berita</button>
									</div>
									<div class="btn-group mr-4 mb-4" role="group" aria-label="Third group" v-for="category in data_category" :key="category.id">
										<button type="button" class="btn btn-berita" :class="{ active: isActive(category.slug) }" v-on:click="filter_by('cat',category.slug)">{{ category.category }}</button>
									</div>
									<div class="btn-group mr-4 mb-4" role="group" aria-label="Third group">
										<button type="button" class="btn btn-berita" :class="{ active: isActive('pdf') }" v-on:click="filter_by('type','pdf')">PDF</button>
									</div>
									<div class="btn-group mb-4" role="group" aria-label="Third group">
										<button type="button" class="btn btn-berita" :class="{ active: isActive('link') }" v-on:click="filter_by('type','link')">Link</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="wrap pt-0">
			<div class="container">
				<div class="row">
					<div class="col-lg-3 col-sm-12 text-center mb-4"  v-for="item in data_berita" :key="item.id">
						<div class="card text-white card-container-berita">
							<div class="over-bg">
								<img class="card-img card-img-berita" :src="item.thumbnail" alt="Card image">
							</div>
							<div class="card-img-overlay">
								<div class="card head berita mx-auto">
									<div class="card-body tag-head">
										{{ item.kategori }}
									</div>
								</div>
								<p class="judul-berita text-center" v-html="item.judul"></p>
								<div class="tgl-berita">
									<div class="divider-custom-2-white m-1">
										<div class="divider-custom-line-2-white"></div>
									</div>
									<p class="card-text	tag-footer">
										{{ item.tgl }}
									</p>
								</div>
							</div>
							<div class="card-footer">
								<button v-if="item.redirect_link" class="btn btn-outline-primary btn-sm d-inline mr-1" v-on:click="goLink(item.redirect_link)"><i class="fa fa-link"></i> Buka Tautan</button>
								<button v-else-if="item.file" class="btn btn-outline-info btn-sm d-inline mr-1" v-on:click="openModalPDF(item.file)"><i class="fa fa-eye"></i> Baca Dokumen</button>
								<button v-else class="btn btn-outline-success btn-sm d-inline" v-on:click="bacaBerita(item.id)">Lanjut Baca <i class="fa fa-arrow-circle-right"></i></button>
							</div>
						</div>
					</div>
				</div>
				<div class="row" v-if="nextpage">
					<div class="col-lg-3 mx-auto">
						<button class="btn btn-berita w-100" v-on:click="get_berita()">Muat lebih banyak ...</button>
					</div>
				</div>
			</div>
		</div>
		<a href="#" class="back-to-top"><i class="fa fa-chevron-up"></i></a>
		<div class="modal"  id="openPDF" tabindex="-1" role="dialog">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title"></h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body" v-if="url_pdf">
						<PDFViewer
							:source="url_pdf"
							style="height: 30em;"
							@download="handleDownloadPDF"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style>
	.judul-berita{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 160px;
	}
	.tgl-berita{
		position: absolute;
		bottom: 20px;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		text-align: center;
	}
	@media screen and (max-width: 600px){
		.card .berita {
			margin-top: 1em !important;
		}
		#openPDF .modal-dialog {
			max-width: 100% !important;
			top: 10%;
		}
	}
	.p-10{
		padding:0em 10em !important;
	}
	.btn-berita{
		background-color: transparent;
		border: solid 1px #37bea9 !important;
		color: #37bea9;
	}
	.btn-berita.active{
		background-color: #37bea9 !important;
		color: #ffffff;
	}
	.btn-berita:hover{
		background-color: #37bea9;
		color: #ffffff;
	}
	.card-container-berita{
		border: none !important;
		box-shadow: 0 0 5px rgb(0 0 0 / 40%);
		border-radius: 10px !important;
	}
	.card-img-berita{
		border-top-left-radius: 10px !important;
		border-top-right-radius: 10px !important;
		border-bottom-left-radius: 0px !important;
		border-bottom-right-radius: 0px !important;
		object-fit: cover;
		height: 285px;
	}

	.over-bg{
		height: 292px;
	}

	.over-bg:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 82%;
		background: rgb(0 159 101 / 68%);
		opacity: 1;
		left: 0;
		border-top-right-radius: 10px;
		border-top-left-radius: 10px;
	}
	.card-img-overlay{
		bottom:63px !important;
	}
</style>

<script>
	const axios = require('axios').default;
	import $ from "jquery";
	import PDFViewer from 'pdf-viewer-vue'

	export default {
		name: 'VBerita',
		components: {
			PDFViewer,
		},
		data(){
			return{
				data_berita: [],
				data_category: [],
				activeItem:'',
				nextpage: null,
				url_pdf: null,
			}
		},
		mounted(){
			this.data_berita = []
			this.get_berita()
			this.activeItem = 'all'
		},
		methods:{
			isActive: function (menuItem) {
				return this.activeItem === menuItem
			},
			goLink(link){
				window.open(link, '_blank');
			},
			get_berita(){
				var url = 'https://admin.ykep.org/api/post'
				if(this.nextpage){
					url = this.nextpage
				}
				axios
				.get(url)
				.then(response => {
					for (var i = 0; i <= (response.data.posts).length -1; i++) {
						this.data_berita.push({
							id: response.data.posts[i].id,
							kategori: response.data.posts[i].category.name,
							judul: response.data.posts[i].title,
							tgl: response.data.posts[i].published_at,
							file: response.data.posts[i].pdf,
							redirect_link: response.data.posts[i].redirect_link,
							narasi:'',
							img_narasi:'',
							thumbnail: response.data.posts[i].featured_image
						})
						var temp = {
							'category': response.data.posts[i].category.name,
							'slug': response.data.posts[i].category.slug
						}
						if(!this.data_category.some(code => code.category === response.data.posts[i].category.name)){
							this.data_category.push(temp)
						}
					}
					if(response.data.pagination){
						this.nextpage = response.data.pagination
					}else{
						this.nextpage = null
					}
				})
				.catch(function(){
					console.log('FAILURE!!');
				}
				);
			},
			filter_by(by,key){
				this.activeItem = key
				if(by=='type'){
					axios
					.get('https://admin.ykep.org/api/post?type='+key)
					.then(response => {
						//console.log(response.data.posts)
						this.data_berita = []
						for (var i = 0; i <= (response.data.posts).length-1; i++) {
							var tempFilter = {
								id: response.data.posts[i].id,
								judul: response.data.posts[i].title,
								tgl: response.data.posts[i].published_at,
								file: response.data.posts[i].pdf,
								redirect_link: response.data.posts[i].redirect_link,
								narasi:'',
								img_narasi:'',
								thumbnail: response.data.posts[i].featured_image
							}
							if(response.data.posts[i].category){
								tempFilter.kategori = response.data.posts[i].category.name
							}else{
								tempFilter.kategori = ''
							}
							this.data_berita.push(tempFilter)
						}
					})
					.catch(function(){
						console.log('FAILURE!!');
					}
					);
				}else if(by=='cat'){
					axios
					.get('https://admin.ykep.org/api/post/category/'+key)
					.then(response => {
						this.data_berita = []
						for (var i = 0; i <= (response.data.posts).length-1; i++) {
							this.data_berita.push({
								id: response.data.posts[i].id,
								kategori: response.data.posts[i].category.name,
								judul: response.data.posts[i].title,
								tgl: response.data.posts[i].published_at,
								file: response.data.posts[i].pdf,
								redirect_link: response.data.posts[i].redirect_link,
								narasi:'',
								img_narasi:'',
								thumbnail: response.data.posts[i].featured_image
							})
						}
					})
					.catch(function(){
						console.log('FAILURE!!');
					}
					);
				}
			},
			bacaBerita(id){
				this.$router.push({ path: '/baca-berita', query: { q: id } })
			},
			openModalPDF(link){
				$('#openPDF').modal('show')
				this.url_pdf = link
				//PDFObject.embed(link, "#example1", {forcePDFJS: true,height: "30em"})	
			},
			async handleDownloadPDF() {
				const response = await axios.get(this.url_pdf, {
					responseType: "arraybuffer",
				});
				const blob = new Blob([response.data], { type: "application/pdf" });
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(blob);
				link.download = this.url_pdf;
				link.click();
			},
		}
	};
</script>