<template>
	<div>
		<div class="container-fluid pl-0 pr-0 header-banner">
			<img src="@/assets/img/baner-visi-misi.jpg" class="w-100" alt="">
		</div>
		<div class="wrap">
			<div class="container">
				<header class="section-header">
					<h1 class="tebal-font-1 text-center">Visi</h1>
				</header>
				<div class="row mt-3">
					<div class="col-md-12">
						<div class="text-justify">
							<p class="m-0 lh-2 fs-3 text-center">Terwujudnya Yayasan yang Transaparan, Maju dan Profesional guna mendukung kesejahteraan dan mencerdaskan KBAD khususnya dan masyarakat pada umumnya.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="wrap">
			<div class="container">
				<header class="section-header">
					<h1 class="tebal-font-1">Misi</h1>
				</header>
				<div class="row">
					<div class="col-12 d-flex mb-4">
						<div class="ml-2 align-self-center">
							<img class="img-icon-misi" src="@/assets/img/icon-misi-1.webp" alt="Card image">
						</div>
						<div class="ml-4 align-self-center">
							<p class="m-0 text-justify">
								Mengembangkan dan meningkatkan lembaga pendidikan ingkat dasar, menengah dan perguruan tinggi yang berkualitas dan bermafaat, berbasis kejuangan dan cinta tanah air.
							</p>
						</div>
					</div>
					<div class="col-12 d-flex mb-4">
						<div class="ml-2 align-self-center">
							<img class="img-icon-misi" src="@/assets/img/icon-misi-2.webp" alt="Card image">
						</div>
						<div class="ml-4 align-self-center">
							<p class="m-0 text-justify">
								Menyelenggarakan dan mengembangkan bidang usaha dan investasi yang sehat, wajar dan menguntungkan dalam sektor jasa, perdagangan, indistri dan perkebunan.
							</p>
						</div>
					</div>
					<div class="col-12 d-flex">
						<div class="ml-2 align-self-center">
							<img class="img-icon-misi" src="@/assets/img/icon-misi-3.webp" alt="Card image">
						</div>
						<div class="ml-4 align-self-center">
							<p class="m-0 text-justify">
								Menyalurkan bantuan Pendidikan, keagamaan dan sosial kemanusiaan bagi KBAD khususnya dan masyarakat pada umunya.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="wrap bg-1 mt-5">
			<div class="container">
				<div class="row">
					<div class="col-12 text-center">
						<header class="section-header logo-text">
							<h1>Logo</h1>
						</header>
						<img class="logo-penjelasan" src="@/assets/img/logo-ykep.png" >
					</div>	
				</div>
				<div class="row">
					<div class="col logo-text">
						<div class="row mb-3-sm">
							<div class="col-8 align-self-center">	
								<p class="text-justify m-0">
									Bentuk lambang berupa perisai (bulat telur berasal dari bentuk perisai), mengandung arti sebagai penjagaan diri atau perlindungan diri. Maknanya adalah memiliki daya tahan terhadap tantangan, hambatan dan gangguan.
								</p>
							</div>
							<div class="col-4 align-self-center text-center p-0">	
								<img class="w-detaillogo" src="@/assets/img/perisai.png">
							</div>
						</div>
						<div class="row mb-3-sm">
							<div class="col-8 align-self-center">	
								<p class="text-justify m-0">
									Lukisan lambang adalah "Kartika Eka Paksi" berlatar belakang warna merah, yang berarti burung gagah perkasa tanpa tanding menjunjung cita-cita tinggi. Maknanya bahwa Yayasan Kartika Eka Pasi sebagai Yayasan yang berbadan hukum dan berafiliasi dengan TNI Angkatan Darat senantiasa menjunjung cita-cita tingi, yaitu keluhuran nusa dan bangsa serta keprajuritan sejati.
								</p>
							</div>
							<div class="col-4 align-self-center text-center p-0">	
								<img class="w-detaillogo" src="@/assets/img/garuda.png">
							</div>
						</div>
						<div class="row mb-3-sm">
							<div class="col-8 align-self-center">	
								<p class="text-justify m-0">
									Tulisan: Yayasan Kartika Eka Paksi berwarna kuning, berlatar belakang warna hijau dilingkari dua garis dalam dan luar berwarna kuning menunjukan nama Yayasan.
								</p>
							</div>
							<div class="col-4 align-self-center text-center p-0">	
								<img class="w-detaillogo" src="@/assets/img/tulisan-yayasan-eka-paksi.png">
							</div>
						</div>
						<div class="row">
							<div class="col-8 align-self-center">	
								<p class="text-justify m-0">
									Teks warna lambang dihiasi oleh empat warna, yaitu: Hijau melambangkan kesuburan, kemakmuran, kesegaran, kedamaian.
									Merah bermakna kekuatan, semangat, energi, keberanian, semangat. Putih: kejujuran,kesucian,bersih, kebebasan. Sedangkan kuning mengandung makna keagungan, kemuliaan, bahagia dan optimis.
								</p>
							</div>
							<div class="col-4 align-self-center text-center p-0">	
								<img class="w-detaillogo" src="@/assets/img/4-warna.png">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<a href="#" class="back-to-top"><i class="fa fa-chevron-up"></i></a>
	</div>
</template>
<style>
	@media screen and (max-width: 600px) {
		.logo-penjelasan{
			width: 30% !important;
		}
		.logo-text{
			margin-top: 3em;
		}
		.mb-3-sm{
			margin-bottom: 3em;
		}
		.w-detaillogo{
			width: 85% !important;
		}
	}
	.w-detaillogo{
		width: 55%;
	}
	.logo-penjelasan{
		width: 10%;
	}
	.tebal-font-1{
		font-weight: 800
	}
	.p-10{
		padding:0em 10em !important;
	}
	.icon-misi{	
		text-align: center;
		font-size: 4em;
	}
	.logo-text{
		color: #ffffff !important;
	}
	.img-icon-misi{
		width: 4em;
	}
	.lh-2{
		line-height: 28px;
	}
	.fs-2{
		font-size: 17px;
		font-weight: 400;
	}
	.fs-3{
		font-size: 17px;
		font-weight: 500;
	}
</style>