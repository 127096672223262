<template>
	<div class="pengurus">
		<div class="container-fluid pl-0 pr-0 header-banner">
			<img src="@/assets/img/baner-pengurus.jpg" class="w-100" alt="">
		</div>
		<div class="wrap">
			<div class="container">
				<div v-for="pengurusItem of pengurusItems" :key="pengurusItem.id">
					<div class="row mb-4">
						<div class="col-md-12 col-lg-6 mx-auto">
							<div class="card card-pengurus">
								<div class="row">
									<div class="col-4">
										<img :src="pengurusItem.img" class="img-pengurus">
									</div>
									<div class="col-8 align-self-center bungkus-nama-pengurus">
										<h5 class="nama-pengurus"><b>{{ pengurusItem.nama }}</b></h5>
										<p class="jabatan-pengurus">
											{{ pengurusItem.jabatan }}
											<br>
											{{ pengurusItem.instansi }}
										</p>
										<p class="text-btn-pengurus mb-0 btn-pengurus" v-on:click="openModalPengurus(pengurusItem)">
											BACA SELENGKAPNYA <a class="text-danger"><i class="fa fa-chevron-circle-right"></i></a>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<a href="#" class="back-to-top"><i class="fa fa-chevron-up"></i></a>
		<div class="modal"  id="pengurusModal" tabindex="-1" role="dialog">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">{{ modal_jabatanPengurus }}</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-12">	
								<div class="row mx-auto mb-3">
									<div class="col-12 text-center"><img :src="data_firstModal.img" class="img-modal-pengurus" alt=""></div>
									<!-- <button class="btn btn-sm btn-info mx-auto mt-3" v-on:click="openPDFPengurus(data_firstModal.link_profil)">Lihat Profil Lengkap</button> -->
								</div>
								<p class="pl-4 mb-3"><b>{{ data_firstModal.nama }}</b></p>
								<p class="pl-4 mb-3">
								<span class="pl-3">PANGKAT / CORPS : {{ data_firstModal.pangkat }}</span><br>
								<span class="pl-3">NRP / NIP : {{ data_firstModal.nrp }}</span><br>
								<span class="pl-3">Tempat Tanggal Lahir : {{ data_firstModal.ttl }}</span><br>
								<span class="pl-3">TMT TNI : {{ data_firstModal.tmttni }}</span><br>
								<span class="pl-3">SUKU BANGSA : {{ data_firstModal.sukubangsa }}</span><br>
								<span class="pl-3">AGAMA : {{ data_firstModal.agama }}</span><br>
								<span class="pl-3">GOl. DARAH : {{ data_firstModal.goldarah }}</span><br>
								</p>
								<p class="pl-4 mb-3">
								Riwayat Pendidikan Terakhir  <br>
								<span class="pl-3">{{ data_firstModal.pendakhir }}</span>
								</p>
								<p class="pl-4 mb-3">
								Pendidikan Militer <br>
								<span class="pl-3" v-for="mil in data_firstModal.pendmiliter" :key="mil.id">{{ mil }}<br></span> 	
								</p>
								<p class="pl-4 mb-3" v-if="modal_karirs.length">
								Perjalanan Karir <br>
								<span class="pl-3" v-for="modal_karir in modal_karirs" :key="modal_karir.id">{{ modal_karir }} <br></span> 
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal" id="myModal2" data-backdrop="static">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">Profil Lengkap</h4>
						<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
					</div><div class="container"></div>
					<div class="modal-body" v-if="url_pdf">
						<PDFViewer
							:source="url_pdf"
							style="height: 30em;"
							@download="handleDownloadPDF"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style>
	@media screen and (max-width: 600px) {
		#pengurusModal .modal-dialog {
			max-width: 100% !important;
			top: 10%;
		}
		.bungkus-nama-pengurus{
			padding-top: 10px;
			padding-bottom: 10px;
		}
		.nama-pengurus {
			font-size: 15px !important;
			margin-bottom: auto;
		}
		.jabatan-pengurus {
			line-height: 12px !important;
			font-size: 10px;
			margin-bottom: auto;
			margin-top: 2px;
		}
		.text-btn-pengurus {
			font-size: 10px !important;
		}
		.text-btn-pengurus  {
			font-size: 12px;
		}
	}
	.img-modal-pengurus .fa{
		width: 25%;
	}
	#pengurusModal .modal-dialog {
		max-width: 45%;
	}
	#pengurusModal .modal-dialog .modal-content{
		border-radius: 15px;
	}
	#pengurusModal .modal-dialog .modal-content .modal-header{
		border-bottom: none;
		padding-left: 3em;
	}
	#pengurusModal .modal-dialog .modal-content .modal-header h5{
		font-weight: 500;
	}
	.card-pengurus{
		border-radius: 15px !important;
		border: none;
		box-shadow: 0 0 5px rgb(0 0 0 / 20%);
	}
	.card-pengurus .card-body{
		padding: 0px;
	}
	.img-pengurus{
		border-top-left-radius: 15px;
		border-bottom-left-radius: 15px;
		width: 100%;
		object-fit: cover;
	}
	.nama-pengurus{
		font-size: 19px;
	}
	.jabatan-pengurus{
		line-height: 21px;
		font-weight: 500;
	}
	.text-pengurus{
		padding: 1em 0em;
	}
	.text-btn-pengurus{
		font-size: 14px;
	}
	.text-btn-pengurus a{
		font-size: 17px;
	}
	.btn-pengurus:hover{
		cursor: pointer;
	}
	@media screen and (max-width: 600px) {
		.header-banner img {
			transform: scale(1.5);
			margin-top: 50px;
		}
		.header-banner{
			height: 30vh;
		}
		.header-banner{
			margin-top: 4em;
			margin-bottom: 2em;
		}
	}
</style>

<script>
	import $ from "jquery";
	const axios = require('axios').default;
	import PDFViewer from 'pdf-viewer-vue'

	export default {
		name: 'VPengurus',
		components: {
			PDFViewer,
		},
		data(){
			return{
				data_firstModal: [],
				modal_namaPengurus: '',
				modal_imgPengurus: '',
				modal_jabatanPengurus: '',
				modal_ttlPengurus:'',
				modal_domisiliPengurus:'',
				modal_dasarPengurus:'',
				modal_pendidikans:[],
				modal_karirs:[],
				pengurusItems: [
				{
					nama : 'Tatang Sulaiman, S.Sos., M.Si.',
					link_profil : 'https://drive.google.com/file/d/19w41jVAoy_pDAj-t0AKOWNPxfgqgvhY9/preview',
					img : require('@/assets/img/ketua-ykep.webp'),
					jabatan : 'Ketua Pengurus Yayasan',
					instansi : 'YKEP',
					ttl : 'Bandung, 1 April 1962',
					pangkat: 'LETJEN (Purn)',
					nrp: '30780',
					tmttni: '20-09-1986',
					kategori: 'AKTIF',
					tmtkategori: '20-09-1986',
					sukubangsa: 'SUNDA',
					agama: 'ISLAM',
					goldarah: 'B',
					sumberperwiira: 'Akmil 1986',
					tmtperwira: '20-09-1986',
					jabatantni: 'WAKASAD',
					tmtjabatan: '21-10-2017',
					kesatuan: 'DENMA MABBESAD',
					pendakhir: 'Megister Sains',
					pendmiliter: ['Seskoad 	THN  1999', 'Sesko TNI 	THN 2010', 'LEMHANNAS THN 2012'],
					kewarganegaraan : 'Warga Negara Indonesia, berdomisili di Indonesia',
					dasarPenunjukan: 'Keputusan KASAD selaku Ketua Pembina YKEP Mengangkat Sdr. Tatang Sulaiman, S.Sos., M.Si. Sebagai Ketua Pengurus YKEP',
					pendidikan: ['S1 : Universitas Jenderal Achmad Yani','S2 : Universitas Jenderal Achmad Yani','S3 : Universitas Gadjah Mada'],
					perjalnanKarir: ['KAPUSPEN TNI 10-09-2015','PANGDAM ISKANDAR MUDA 16-09-2016','PANGDAM IV/ DIPONEGORO 31-03-2017','WAKASAD 27-10-2017','KETUA PENGURUS YKEP 29-09-2020 - Sekarang']
				},
				{
					nama : 'Moch. Bambang Taufik',
					link_profil : 'https://drive.google.com/file/d/1PhrseTgzugFfM3KvZEsry741fsxWBFoX/preview',
					img : require('@/assets/img/wakil-ketua.webp'),
					jabatan : 'Wakil Ketua Yayasan',
					instansi : 'YKEP',
					ttl : 'Kab. Bandung, 09 November 1961',
					pangkat: 'MAYOR JENDERAL TNI/INF',
					nrp: '30029',
					tmttni: '27-09-1984',
					kategori: 'AKTIF',
					tmtkategori: '27-09-1984',
					sukubangsa: 'SUNDA',
					agama: 'ISLAM',
					goldarah: 'O',
					sumberperwiira: 'AKMIL',
					tmtperwira: '27-09-1984',
					jabatantni: '',
					tmtjabatan: '24-12-2019',
					kesatuan: '',
					pendakhir: 'AKMIL',
					pendmiliter: ['DIKLAPA-II/INF 1995', 'SESKOAD 1998', 'SESKO PAKISTAN 1999'],
					kewarganegaraan : 'Warga Negara Indonesia, berdomisili di Indonesia',
					dasarPenunjukan: 'Keputusan KASAD selaku Ketua Pembina YKEP Mengangkat Sdr. M. BAMBANG TAUFIK  Sebagai Wakil Ketua Pengurus YKEP',
					pendidikan: [],
					perjalnanKarir: ['WAASPAM KASAD 02-05-2014','KASDAM XVI/PTM 24-04-2015','DALAM RANGKA PENSIUN 24-12-2019','KAS KOSTRAD 24-12-2019','WAKIL II KETUA PENGURUS YKEP 29-09-2020 – Sekarang']
				},
				{
					nama : 'Arifin, S.E., M.M.',
					link_profil : 'https://drive.google.com/file/d/16x8zFOJPuU4EhrHpoggdPBaPyW2zINI0/preview',
					img : require('@/assets/img/sekertaris.webp'),
					jabatan : 'Sekertaris',
					instansi : 'YKEP',
					ttl : 'Garut, 10 April 1962',
					pangkat: 'BRIGJEN TNI (Purn)',
					nrp: '30781',
					tmttni: '20-09-1986',
					sukubangsa: 'SUNDA',
					agama: 'ISLAM',
					goldarah: 'A',
					sumberperwiira: 'AKMIL',
					tmtperwira: '',
					jabatantni: 'PATIH SAHLI TINGKAT II LH, BID. WASUS LH, PANGLIMA TNI',
					tmtjabatan: '19-02-2020',
					kesatuan: '',
					pendakhir: 'Magister Management  Universitas Krisnadwipayana',
					pendmiliter: ['DIKLAPA II/INF 1996','SESKOAD 2002','SESKOTNI 2010'],
					kewarganegaraan : 'Warga Negara Indonesia, berdomisili di Indonesia',
					dasarPenunjukan: '',
					pendidikan: ['S1 : Universitas Krishna Dwipayana','S2 : Universitas Krishna Dwipayana'],
					perjalnanKarir: ['PAMEN DENMA MABESAD 25-09-2012','PABUT OPS SAHLI PANGLIMA TNI 14-10-2016','PABUT POLDAGRI SAHLI BID. POLKAMNAS PANGLIMA TNI 29-12-2017','PATI SAHLI TINGKAT II BIDANG LH PANGLIMA TNI 19-02-2020','Sekertaris YKEP 29-09-2020 – Sekarang']
				},
				{
					nama : 'Dr. Drs. Yudi Sutrasna, M.M.',
					link_profil : 'https://drive.google.com/file/d/1k0fr9wtMmy1f2AorG0ufGpMDDoeAbhE-/preview',
					img : require('@/assets/img/bendahara.webp'),
					jabatan : 'Bendahara',
					instansi : 'YKEP',
					ttl : 'JAKARTA BARAT, 19 Oktober 1961',
					pangkat: 'BRIGADIR JENDERAL TNI / CKU',
					nrp: '31803',
					tmttni: '01-08-1988',
					sukubangsa: 'SUNDA',
					agama: 'ISLAM',
					goldarah: '',
					sumberperwiira: '',
					tmtperwira: '01-08-1988',
					jabatantni: '',
					tmtjabatan: '30-10-2019',
					kesatuan: '',
					pendakhir: 'MAGISTER MANAGEMEN',
					pendmiliter: ['SUSLAPA-I/KU 1996','DIKLAPA-II/KU 1999','SESKOAD 2002'],
					kewarganegaraan : 'Warga Negara Indonesia, berdomisili di Indonesia',
					dasarPenunjukan: '',
					pendidikan: ['FAKULTAS EKONOMI JURUSAN UMUM','S2: MAGISTER MANAGEMEN'],
					perjalnanKarir: ['PAPEKAS TNI WILAYAH NAD 07-01-2005','KASUBBAGGARBIA BAGKU PUSKU TNI 31-07-2006','PAPEKAS TNI WIL. JAKARTA II 01-07-2008','DALAM RANGKA PENSIUN 30-10-2019','Bendahara YKEP 29-09-2020 – Sekarang']
				},
				{
					nama : "H. Moch Syafi'ul Muchtar M.B.A",
					link_profil : 'https://drive.google.com/file/d/1OWc3dzgKqva0qz9qgyrznPtLEJk-oLJy/preview',
					img : require('@/assets/img/kabid-usaha.webp'),
					jabatan : 'Kepala Bidang Usaha & Investasi',
					instansi : 'YKEP',
					ttl : 'Surabaya, 01 Januari 1961',
					pangkat: 'BRIGADIR JENDERAL TNI / INF',
					nrp: '30415',
					tmttni: '28-09-1985',
					sukubangsa: 'JAWA',
					agama: 'ISLAM',
					goldarah: '',
					sumberperwiira: '',
					tmtperwira: '01-01-1970',
					jabatantni: 'TUNGGU JABATAN',
					tmtjabatan: '28-03-2012',
					kesatuan: 'DENMABES TNI',
					pendakhir: 'AKMIL',
					pendmiliter: ['Akmil 1985','Sussarcab if','Suspapers','DIKLAPA I&II', 'Sesko AD','Suspa Intelgen Strategis','Sus ATHAN','NDU/ Lemhanas Thailand '],
					kewarganegaraan : 'Warga Negara Indonesia, berdomisili di Indonesia',
					dasarPenunjukan: '',
					pendidikan: [],
					perjalnanKarir: ['Pabandya Pampers Sintel TNI','Danyonif Linud-700 Dam VII/ WRB','Dandim- 1408/Pare Pare Dam VII/ WRB ','PBDYA C-43 DIT "C" BAIS TNI 01-01-2006','ATHAN RI Di Manila/Philipina 01-04-2006','PAMEN MABES TNI 21-08-2009','DANSEINTELSTRAT SATINDUK BAIS 14-05-2010','Komandan Sekolah Inteljen strategis BAIS TNI','Kepala Korps Dosen Pusdik Intel Strategis BAIS TNI','Asisten Deputy Bidang  Ling Al Wantannas ','Ka Dest Papua Kemenko Polhukam','Kepala Bidang Usaha & Investasi YKEP – Sekarang']
				},
				{
					nama : "Benny Indra Pujihastono, S.I.P",
					link_profil : 'https://drive.google.com/file/d/1Mh0Sj5Od-8bKrIkHlh1dcmSUI9iRdmGf/preview',
					img : require('@/assets/img/kabid-pendidikan.webp'),
					jabatan : 'Kepala Bidang Pendidikan',
					instansi : 'YKEP',
					ttl : 'SEMARANG, 02 Desember 1961',
					pangkat: 'MAYOR JENDERAL TNI / ARM',
					nrp: '30080',
					tmttni: '27-09-1984',
					sukubangsa: 'JAWA',
					agama: 'ISLAM',
					goldarah: 'O',
					sumberperwiira: 'AKABRI',
					tmtperwira: '27-09-1984',
					jabatantni: 'DALAM RANGKA PENSIUN',
					tmtjabatan: '17-12-2019',
					kesatuan: 'DENMA MABESAD',
					pendakhir: 'SARJANA SOSIAL POLITIK',
					pendmiliter: ['SESKOAD 1998','SESKO TNI 2008','LEMHANNAS RI 2012'],
					kewarganegaraan : 'Warga Negara Indonesia, berdomisili di Indonesia',
					dasarPenunjukan: '',
					pendidikan: ['S1: Universitas Jendral Achmad Yani','S2: sedang dilaksanakan di Universitas Jendral Achmad Yani'],
					perjalnanKarir: ['KASREM 064/MY DAM III/SLW 15-02-2006','DANMENARMED 1/2 KOSTRAD 15-05-2006','DANMENARMED 2/1 KOSTRAD 21-09-2007','SAHLI PANGDAM IX/UDY BID JEMSISHANNEG 27-06-2008','Kepala Bidang Pendidikan YKEP 29-09-2020 – Sekarang']
				},
				{
					nama : "Abdul Karim",
					link_profil : 'https://drive.google.com/file/d/1-Gt1GvDtDPyKHGws-rCDuBDV-70I-fwS/preview',
					img : require('@/assets/img/kabid-kesejahteraan.webp'),
					jabatan : 'Kepala Bidang Kesejahteraan &',
					instansi : 'Hubungan Kelembagaan YKEP',
					ttl : 'LAHAT, 09 Juli 1960',
					pangkat: 'BRIGADIR JENDERAL TNI / INF',
					nrp: '30785',
					tmttni: '20-09-1986',
					sukubangsa: 'PALEMBANG',
					agama: 'ISLAM',
					goldarah: 'AB',
					sumberperwiira: 'AKABRI',
					tmtperwira: '20-09-1986',
					jabatantni: 'PENSIUN',
					tmtjabatan: '26-07-2017',
					kesatuan: 'DISBINTALAD',
					pendakhir: 'AKMIL',
					pendmiliter: ['SUSLAPA-I/INF 1992','DIKLAPA-II/INF 1995','SESKOAD 2001'],
					kewarganegaraan : 'Warga Negara Indonesia, berdomisili di Indonesia',
					dasarPenunjukan: '',
					pendidikan: [],
					perjalnanKarir: []
				},
				],
				url_pdf: null,
			}
		},
		methods:{
			openModalPengurus(item){
				this.data_firstModal = item;
				this.modal_namaPengurus = item.nama
				this.modal_imgPengurus = item.img
				if(item.nama == 'Abdul Karim'){
					this.modal_jabatanPengurus = 'Kepala Bidang Kesejahteraan & Hubungan Kelembagaan'	
				}else{
					this.modal_jabatanPengurus = item.jabatan
				}
				this.modal_ttlPengurus = item.ttl
				this.modal_domisiliPengurus = item.kewarganegaraan
				this.modal_dasarPengurus = item.dasarPenunjukan
				this.modal_pendidikans = item.pendidikan
				this.modal_karirs = item.perjalnanKarir
				$('#pengurusModal').modal('show')
			},
			openPDFPengurus(linkpdf){
				$('#myModal2').modal('show')
				this.url_pdf = linkpdf
				//PDFObject.embed(linkpdf, "#pdfPengurus", {forcePDFJS: true,height: "30em"})
			},
			async handleDownloadPDF() {
				const response = await axios.get(this.url_pdf, {
					responseType: "arraybuffer",
				});
				const blob = new Blob([response.data], { type: "application/pdf" });
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(blob);
				link.download = this.url_pdf;
				link.click();
			},
		}
	};
</script>